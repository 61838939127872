import { Controller } from 'stimulus';

/**
 * Required Signature Controller
 */
class SignatureButtonController extends Controller {
  initialize() {
    this.formElement = this.resultTarget.closest('form');
    this.checkRequiredField();

    this.formElement.addEventListener('change', () => {
      this.checkRequiredField();
    });
  }

  checkRequiredField() {
    this.fieldValues = [];

    this.formElement.querySelectorAll('.form-field, .form-group').forEach((field) => {
      if ($(field).hasClass('required')) {
        if ($('select', field)[0]) {
          this.fieldValues.push($('select', field).val());
        } else if ($('input', field).not(':input[type=hidden], :input[type=select-multiple]')[0]) {
          this.fieldValues.push($('input', field).val());
        } else if ($('textarea', field)[0]) {
          this.fieldValues.push($('textarea', field).val());
        }
      }
    });

    this.formElement.querySelectorAll('.btn-sign').forEach((button) => {
      /* eslint no-param-reassign: ["error",
                                   { "props": true,
                                   "ignorePropertyModificationsFor": ["button"]
                                   }] */

      if (SignatureButtonController.checkPresence(this.fieldValues)) {
        button.disabled = false;
      } else {
        button.disabled = true;
      }
    });
  }


  /* eslint-disable */
  static checkPresence(values) {
    return values.every((element) => {
      return element.length !== 0;
    });
  }
  /* eslint-enable */
}

export default SignatureButtonController;
