import { Controller } from 'stimulus';
/**
 * Show sign window
 */
class FormController extends Controller {
  /* global SignaturePad */
  initialize() {
    this.signaturePad = new SignaturePad(this.canvasTarget);
  }

  setSignature() {
    if (this.signaturePad.isEmpty()) {
      return;
    }
    $('.js-new-form-modal')[0].dispatchEvent(new CustomEvent('signed', {
      detail: { sign: this.signaturePad.toDataURL() },
    }));
  }

  clear() {
    this.signaturePad.clear();
  }
}

FormController.targets = ['canvas'];

export default FormController;
