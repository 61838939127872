import Trix from 'trix/dist/trix';
import { Controller } from 'stimulus';

class RedFontController extends Controller {
  connect() {
    Trix.config.textAttributes.red = {
      style: { color: 'red' },
      parser(element) { return element.style.color === 'red'; },
      inheritable: true,
    };

    const buttonHTML = '<button type="button" class="trix-button" data-trix-attribute="red" tabindex="-1">RED</button>';

    this
      .element
      .parentElement
      .querySelector('trix-toolbar')
      .querySelector('.trix-button-group--text-tools')
      .insertAdjacentHTML('beforeend', buttonHTML);

    this.element.reset();
  }
}

export default RedFontController;
