import Trix from 'trix/dist/trix';
import { Controller } from 'stimulus';

class CenterTextController extends Controller {
  connect() {
    Trix.config.blockAttributes.textAlignCenter = { tagName: 'centered-div' };

    const centerTextButtonHTML = '<button type="button" class="trix-button" '
                                 + 'data-trix-attribute="textAlignCenter" '
                                 + 'title="Align center">'
                                 + '<i class="fa fa-align-center"></i>'
                                 + '</button>';

    this
      .element
      .parentElement
      .querySelector('trix-toolbar')
      .querySelector('.trix-button-group--block-tools')
      .insertAdjacentHTML('beforeend', centerTextButtonHTML);
  }
}

export default CenterTextController;
