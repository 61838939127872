import { Controller } from 'stimulus';

/**
 * Disable preview button unless selected fse permit
 */
class PreviewController extends Controller {
  initialize() {
    this.checkPreviewButton();
  }

  connect() {
    this.previewButtonTarget.addEventListener('click', (e) => {
      e.preventDefault();


      if (this.isPermitPicked) {
        window.open(this.previewPath);
      }
    });
  }

  checkPreviewButton() {
    if (this.fsePermitFieldTarget.value === '') {
      this.previewButtonTarget.disabled = true;
    } else {
      this.previewButtonTarget.disabled = false;
    }
  }

  get isPermitPicked() {
    return this.fsePermitFieldTarget.value !== '';
  }

  get previewPath() {
    const previewPrefix = this.previewButtonTarget.dataset.previewPath;
    const permitId = this.fsePermitFieldTarget.value;

    return `${previewPrefix}?fse_permit_preview[id]=${permitId}`;
  }
}

PreviewController.targets = [
  'previewButton', 'fsePermitField',
];

export default PreviewController;
