import { Controller } from 'stimulus';

/**
 * Handle export form
 */
class FormController extends Controller {
  /**
   * Set submit button disabled state with respect to select all check box
   */
  toggle() {
    this.submitTarget.disabled = !(this.triggerTarget.checked);
  }

  /**
   * Set submit button disabled state with respect to all check boxes
   */
  check() {
    this.submitTarget.disabled = !(this.columnsSelected());
  }

  /**
   * Check columns selected before form submission
   * Hide modal if columns selected
   */
  submit(event) {
    if (this.columnsSelected()) {
      $('.js-new-form-modal').modal('hide');
    } else {
      event.preventDefault();
    }
  }

  columnsSelected() {
    return this.checkTargets.some(FormController.checked);
  }

  static checked(item) {
    return item.checked;
  }
}

FormController.targets = [
  'trigger',
  'check',
  'submit',
];

export default FormController;
