import { Controller } from 'stimulus';

class ProviderPermitLayoutPreviewController extends Controller {
  initialize() {
    this.updateButtonDisabledStatus();
  }

  connect() {
    this.previewButtonTarget.addEventListener('click', (event) => {
      event.preventDefault();

      if (this.isVehicleSelected) { window.open(this.previewPath); }
    });
  }

  get isVehicleSelected() {
    return this.vehicleFieldTarget.value !== '';
  }

  get previewPath() {
    return `${this.previewButtonTarget.dataset.previewPath}?vehicle_id=${this.vehicleFieldTarget.value}`;
  }

  updateButtonDisabledStatus() {
    if (this.isVehicleSelected) {
      this.enablePreviewButton();
    } else {
      this.disablePreviewButton();
    }
  }

  enablePreviewButton() {
    this.previewButtonTarget.disabled = false;
  }

  disablePreviewButton() {
    this.previewButtonTarget.disabled = true;
  }
}

ProviderPermitLayoutPreviewController.targets = [
  'previewButton', 'vehicleField',
];

export default ProviderPermitLayoutPreviewController;
