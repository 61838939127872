import ApplicationController from './support/application_controller';

/**
 * Permit Print Maps controller
 */
class PermitTemplateSelectController extends ApplicationController {
  /**
   * Show options input for select fields
   */
  select() {
    this.questionsTarget.innerHTML = '';
    if (this.templateTarget.value !== '') {
      this.loadQuestions(this.templateTarget.value);
      this.loadMandatoryQuestions(this.templateTarget.value);
    }
  }

  loadQuestions(templateId) {
    fetch(`/providers/permit_templates/${templateId}/questions`,
      {
        credentials: 'same-origin',
      })
      .then(response => response.text()).then((html) => {
        this.questionsTarget.innerHTML = html;
      });
  }

  loadMandatoryQuestions(templateId) {
    fetch(`/providers/permit_templates/${templateId}/mandatory_questions`,
      {
        credentials: 'same-origin',
      })
      .then(response => response.text()).then((html) => {
        this.mandatoryQuestionsTarget.innerHTML = html;
      });
  }
}

PermitTemplateSelectController.targets = ['mandatoryQuestions', 'questions', 'template'];

export default PermitTemplateSelectController;
