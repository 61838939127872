import { Controller } from 'stimulus';

class TemplateSelectController extends Controller {
  connect() {
    this.templateSelectTarget.addEventListener('change', (event) => {
      const templateId = event.target.value;

      if (templateId === '') { return; }

      const templateLoadPath = this.constructor.buildTemplateLoadPath(templateId);

      fetch(
        templateLoadPath,
      ).then(
        response => response.text(),
      ).then(
        (html) => { this.templateContainerTarget.innerHTML = html; },
      );
    });
  }

  static buildTemplateLoadPath(templateId) {
    throw new Error(
      `Has to be implemented in a child class! Template ID: ${templateId}`,
    );
  }
}

TemplateSelectController.targets = ['templateSelect', 'templateContainer'];

export default TemplateSelectController;
