import { Controller } from 'stimulus';

class FsePermitPrintController extends Controller {
  connect() {
    this.printButtonTarget.addEventListener('click', (e) => {
      e.preventDefault();

      window.open(this.printButtonTarget.href);
    });
  }
}

FsePermitPrintController.targets = ['printButton'];

export default FsePermitPrintController;
