import { Controller } from 'stimulus';

/**
 * Control select boxes with selectize.js
 */
class SelectizeColorsController extends Controller {
  initialize() {
    this.$element = $(this.element);
  }

  connect() {
    this.$element.selectize({
      persist: false,
      closeAfterSelect: true,
      create: false,
      render: {
        item: (item, escape) => (`<div class="text-${escape(item.value)}">${escape(item.text)}</div>`),
        option: (item, escape) => (`<div class="p-2 text-${escape(item.value)}">${escape(item.text)}</div>`),
      },
    });
  }

  disconnect() {
    this.$element[0].selectize.destroy();
  }
}

export default SelectizeColorsController;
