import { Controller } from 'stimulus';

class DesktopViewController extends Controller {
  connect() {
    this.switchViewButtonTarget.addEventListener('click', (event) => {
      event.preventDefault();

      this.tableTarget.classList.toggle('desktop_view');

      DesktopViewController.saveDesktopViewState();
    });
  }

  static saveDesktopViewState() {
    const url = '/city/toggle-desktop-view';
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
    };

    fetch(url, { method: 'POST', headers });
  }
}

DesktopViewController.targets = ['switchViewButton', 'table'];

export default DesktopViewController;
