import { Controller } from 'stimulus';

class FormController extends Controller {
  initialize() {
    FormController.fetchDynamicRanges((data) => {
      this.dynamicRanges = data;
    });
  }

  onSubmitClick(event) {
    if (this.isBetweenRangeSelected() === true && this.dateFieldsValid() === false) {
      event.preventDefault();
      this.fromDateToHighError();
    }
  }

  dateChanged() {
    if (this.isFromDatePresent() === true && this.isToDatePresent() === true) {
      this.fromDateToHighError();
    }
  }

  fromDateToHighError() {
    const toHighErrorMsg = $(this.fromDateWrapperTarget).find('.to-high');
    if (this.isFromDateLessOrEqualToDate() === false) {
      toHighErrorMsg.css('display', 'block');
    } else {
      toHighErrorMsg.css('display', 'none');
    }
  }

  isBetweenRangeSelected() {
    return this.hasDateRangeInputTarget && this.dateRangeInputTarget.value === 'between';
  }

  dateFieldsValid() {
    return this.isFromDateValid() === true && this.isToDatePresent() === true;
  }

  isFromDateValid() {
    return this.isFromDatePresent() === true && this.isFromDateLessOrEqualToDate() === true;
  }

  isFromDatePresent() {
    return $(this.fromDateWrapperTarget).find('input').val() !== '';
  }

  isToDatePresent() {
    return $(this.toDateWrapperTarget).find('input').val() !== '';
  }

  isFromDateLessOrEqualToDate() {
    const from = Date.parse($(this.fromDateWrapperTarget).find('input').val());
    const to = Date.parse($(this.toDateWrapperTarget).find('input').val());
    return from <= to;
  }

  hideDateForm() {
    this.dateFormTarget.classList.add('d-none');
  }

  clearAdditionalDateRangeInput() {
    this.additionalDateRangeFormTarget.innerHTML = '';
  }

  showDateForm() {
    this.dateFormTarget.classList.remove('d-none');
  }

  showDateMatch() {
    this.showDateForm();
    this.loadForm('date_match');
  }

  showDateRange() {
    this.showDateForm();
    this.loadForm('date_range').then(() => {
      this.updateDateRangeHiddenValue();
    });
  }

  dateRangeChanged() {
    const range = this.dateRangeInputTarget.value;
    if (this.dynamicRanges.includes(range)) {
      this.loadAdditionalDateRangeInput(range).then(() => {
        this.directionChanged();
      });
    } else if (range === 'between') {
      this.loadAdditionalDateRangeInput(range);
    } else {
      this.clearAdditionalDateRangeInput();
    }
    this.updateDateRangeHiddenValue();
  }

  updateDateRangeHiddenValue(direction) {
    const range = this.dateRangeInputTarget.value;

    if (direction === undefined) {
      this.dateRangeHiddenInputTarget.value = range;
    } else {
      this.dateRangeHiddenInputTarget.value = (`${range}_${direction}`);
    }
  }

  directionChanged() {
    const direction = this.dateRangeDirectionInputTarget.value;
    this.updateDateRangeHiddenValue(direction);
  }

  loadForm(name) {
    return new Promise(((resolve) => {
      FormController.fetchData(this.formUrl(name), (data) => {
        this.dateFormTarget.innerHTML = data;
        resolve(this.dateFormTarget);
      });
    }));
  }

  loadAdditionalDateRangeInput(range) {
    return new Promise(((resolve) => {
      FormController.fetchData(this.additionalDateFieldsUrl(range), (data) => {
        this.additionalDateRangeFormTarget.innerHTML = data;
        resolve(this.additionalDateRangeFormTarget);
      });
    }));
  }


  static fetchData(url, callback) {
    return fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
      .then(response => response.text())
      .then((data) => {
        callback(data);
      });
  }

  static fetchDynamicRanges(callback) {
    return fetch('/city/custom_property_filter_forms/dynamic_ranges_list.json', { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
      .then(response => response.json())
      .then((data) => {
        callback(data);
      });
  }

  formUrl(name) {
    return `${this.baseUrl()}/date_form.js?form=${name}`;
  }

  additionalDateFieldsUrl(range) {
    return `${this.baseUrl()}/fields_for_date_range.js?range=${range}`;
  }

  baseUrl() {
    return `/city/custom_property_filter_forms/${this.dateFormTarget.dataset.customPropertyId}`;
  }
}

FormController.targets = ['dateForm', 'additionalDateRangeForm', 'dateRangeInput',
  'dateRangeHiddenInput', 'dateRangeCountInput', 'dateRangeDirectionInput',
  'fromDateWrapper', 'toDateWrapper'];

export default FormController;
