import { Controller } from 'stimulus';

/**
 * Search establishments by name or address
 */
class EstablishmentSearchController extends Controller {
  initialize() {
    this.options = [];
    this.selectedValues = this.element.value.split(' ');
    this.$element = $(this.element);
  }

  connect() {
    this.select = this.init();
  }

  disconnect() {
    this.$element[0].selectize.destroy();
  }

  async init() {
    if (this.element.value !== '') {
      this.options = await this.fetchOptions();
    }

    this.$element.selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: ['name', 'business_address'],
      options: this.options,
      create: false,
      persist: false,
      maxItems: this.maxItems,
      render: {
        item: (item, escape) => {
          this.element.dataset.item_path = `${this.path}/${item.reference_no}`;
          this.element.dataset.name = escape(item.name);
          this.element.dataset.businessAddress = escape(item.business_address);
          return `<div class="d-flex flex-column"><div>${escape(item.name)}</div><small>${escape(item.business_address)}</small></div>`;
        },
        option: (item, escape) => `<div class="list-group-item p-2 d-flex flex-column"><div>${escape(item.name)}</div><small>${escape(item.business_address)}</small></div>`,
      },
      load: this.fetchData.bind(this),
      onChange: this.changed.bind(this),
    });
  }

  get maxItems() {
    return parseInt(this.data.get('maxItems'), 10);
  }

  get path() {
    return this.data.get('path');
  }

  static async promiseSerial(funcs) {
    return funcs.reduce((p, f) => p.then(r => f().then(Array.prototype.concat.bind(r))),
      Promise.resolve([]));
  }

  static fetchEstablishment(url) {
    return fetch(url, { credentials: 'same-origin' })
      .then(response => response.json()).then(json => json.establishment);
  }

  async fetchOptions() {
    const urls = this.selectedValues.map(id => `${this.path}/${id}.json`);
    const funcs = urls.map(url => () => EstablishmentSearchController.fetchEstablishment(url));

    return EstablishmentSearchController.promiseSerial(funcs).then(result => result);
  }

  fetchData(query, callback) {
    this.query = query;
    if (!query.length || query.length < 2) return callback();
    return fetch(`${this.path}.json?${encodeURIComponent('q[search_by_name_or_address]')}=${encodeURIComponent(query)}`, { credentials: 'same-origin' })
      .then(response => response.json())
      .then((data) => {
        callback(data.establishments);
      });
  }

  changed() {
    const forms = this.$element.closest('form');

    if (forms.length > 0) { forms[0].dispatchEvent(new Event('change')); }

    this.element.dispatchEvent(new Event('change'));
  }
}

export default EstablishmentSearchController;
