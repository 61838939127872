import Chart from 'chart.js';
import ApplicationController from '../support/application_controller';

/**
 * Chart compliance history
 */
class InspectionsChartController extends ApplicationController {
  connect() {
    this.checkForSessionStorageDates();

    this.render();
  }

  checkForSessionStorageDates() {
    if (sessionStorage.getItem('defaultFromDate')) {
      this.element.dataset.from = sessionStorage.getItem('defaultFromDate');
    }

    if (sessionStorage.getItem('defaultToDate')) {
      this.element.dataset.to = sessionStorage.getItem('defaultToDate');
    }
  }

  render() {
    this.bodyTargets.forEach((el) => {
      const chart = new Chart(el,
        {
          type: 'doughnut',
          options: {
            legend: { display: false },
          },
        });
      this.fetchData(el.dataset.type).then((json) => {
        chart.data = InspectionsChartController.chartData(json);
        chart.update();
      });
    });
  }

  fetchData(type) {
    const { from, to } = this.element.dataset;

    return fetch(
      `/city/counters/dated/${type}/${from}/${to}.json`,
    ).then(response => response.json());
  }

  /**
   *
   * @return Array of localised dates
   */
  static labels(json) {
    return Object.keys(json);
  }

  static chartData(json) {
    return {
      labels: InspectionsChartController.labels(json),
      datasets: [{
        data: Object.values(json),
        backgroundColor: InspectionsChartController.backgroundColor(json),
      }],
    };
  }

  static backgroundColor(json) {
    return Object.values(json).map(() => {
      const x = Math.floor(Math.random() * 256);
      const y = Math.floor(Math.random() * 256);
      const z = Math.floor(Math.random() * 256);
      return `rgb(${x},${y},${z})`;
    });
  }
}

InspectionsChartController.targets = ['body'];

export default InspectionsChartController;
