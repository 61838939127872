import { Controller } from 'stimulus';

/**
 * Deselects one group of checkboxes if any checkboxes of the opposite group is checked
 */
class UncheckAnotherController extends Controller {
  uncheckOption1() {
    this.uncheckOption(this.option1Target);
  }

  uncheckOption2() {
    this.uncheckOption(this.option2Target);
  }

  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["uncheckOption"] }] */
  uncheckOption(option) {
    option.querySelectorAll('input').forEach((element) => {
      const box = element;
      box.checked = false;
    });
  }
}

UncheckAnotherController.targets = [
  'option1',
  'option2',
];

export default UncheckAnotherController;
