import Trix from 'trix/dist/trix';
import { Controller } from 'stimulus';
import EmbedUploader from './embed_uploader';

/**
 * Manage Trix WYSIWYG embedded attachments
 */
class AttachmentsController extends Controller {
  connect() {
    Trix.config.attachments.preview.caption = {
      name: false,
      size: false,
    };

    this.element.addEventListener(
      'trix-attachment-add',
      this.uploadEmbed.bind(this),
    );
  }

  get url() {
    return this.data.get('direct-upload-url');
  }

  get attachmentFieldName() {
    return this.data.get('attachment-field-name');
  }

  uploadEmbed(event) {
    if (!event.attachment.file) { return; }

    const uploader = new EmbedUploader(
      event.attachment,
      this.url,
      AttachmentsController.appendToForm,
      this.attachmentFieldName,
    );
    uploader.upload();
  }

  static appendToForm(blob, fieldName) {
    const hiddenField = document.createElement('input');

    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('value', blob.signed_id);

    hiddenField.name = fieldName;

    document.querySelector('form').appendChild(hiddenField);
  }
}

export default AttachmentsController;
