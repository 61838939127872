import Trix from 'trix/dist/trix';
import { Controller } from 'stimulus';

class H2Controller extends Controller {
  connect() {
    Trix.config.blockAttributes.heading2 = {
      tagName: 'h2',
      terminal: true,
      breakOnReturn: true,
      group: false,
    };

    const h2ButtonHTML = '<button type="button" class="trix-button" '
                         + 'data-trix-attribute="heading2" title="Heading 2">'
                         + 'H2</button>';

    this
      .element
      .parentElement
      .querySelector('trix-toolbar')
      .querySelector('.trix-button-group--block-tools')
      .insertAdjacentHTML('beforeend', h2ButtonHTML);
  }
}

export default H2Controller;
