import ApplicationController from '../support/application_controller';

/**
 * Load remote data on connect
 */
class LoadHtmlContentController extends ApplicationController {
  /**
   * Retrieve and show data from a data url
   */
  connect() {
    this.loadHtmlContent();
  }

  loadHtmlContent() {
    this.fetchData()
      .then((html) => {
        this.element.innerHTML = html;
      });
  }
}

export default LoadHtmlContentController;
// shared--load-html-content
