import ApplicationController from './support/application_controller';

/**
 * Show or hide selected table columns
 */
class ColumnSelectorController extends ApplicationController {
  static configKey() {
    return 'ColumnSelectorConfig';
  }

  /* eslint no-param-reassign: ["error"] */
  connect() {
    if (this.hasSelectorMenuTarget) {
      this.removeMissingColumnsFromSelector();
      this.updateSelectorItemsConfig();
      this.switchAllColumns();
    }
  }

  updateSelectorItemsConfig() {
    this.selectorItems().forEach((item) => {
      const config = ColumnSelectorController.loadConfig();
      const resourceConfig = config[this.resourceName()];
      if (resourceConfig !== undefined) {
        const input = item.querySelector('input');
        const name = input.dataset.key;
        input.checked = resourceConfig[name];
      }
    });
  }

  tableChanged() {
    if (this.hasSelectorMenuTarget) {
      this.switchAllColumns();
    }
  }

  switchAllColumns() {
    this.selectorItems().forEach((item) => {
      this.switchColumn(item.innerText.trim(), item.querySelector('input').checked);
    });
  }

  reset(e) {
    e.preventDefault();
    this.selectorItems().forEach((item) => {
      item.querySelector('input').checked = true;
      this.switchColumn(item.innerText.trim(), true);
    });
    this.updateConfig();
  }

  selectorItems() {
    return this.selectorMenuTarget.querySelectorAll('.selector-menu-item');
  }

  removeMissingColumnsFromSelector() {
    // Remove columns that are not in the table
    const items = this.selectorItems();
    const tableColumns = this.tableColumnNames().filter(item => item);

    items.forEach((item) => {
      const name = item.querySelector('.switch-item__text').innerText.trim();
      if (!tableColumns.includes(name)) { item.remove(); }
    });
  }

  tableColumnNames() {
    const headers = this.dataTable().querySelectorAll('thead tr th');
    const names = [];

    headers.forEach((header) => {
      names.push(header.innerText.trim());
    });

    return names;
  }

  dataTable() {
    return this.stdMainTarget.querySelector('#data-table');
  }

  columnSwitched(event) {
    const columnName = event.target.parentElement.parentElement.innerText.trim();
    this.switchColumn(columnName, event.target.checked);
    this.updateConfig();
  }

  columnIndex(name) {
    return this.tableColumnNames().indexOf(name);
  }

  switchColumn(name, status) {
    const index = this.columnIndex(name);
    const display = status ? '' : 'none';
    const columnHeader = this.dataTable().querySelectorAll('thead tr th')[index];
    const columnRows = this.dataTable().querySelectorAll('tbody tr');

    columnHeader.style.display = display;

    columnRows.forEach((el) => {
      const tableData = el.querySelectorAll('td')[index];

      tableData.style.display = display;
    });
  }

  updateConfig() {
    const config = ColumnSelectorController.loadConfig();
    config[this.resourceName()] = this.resourceConfig();
    localStorage.setItem(ColumnSelectorController.configKey, JSON.stringify(config));
  }

  resourceName() {
    return this.selectorMenuTarget.dataset.resource;
  }

  static csrfToken() {
    return document.querySelector("meta[name='csrf-token']").content;
  }

  resourceConfig() {
    const config = {};

    this.selectorItems().forEach((item) => {
      const input = item.querySelector('input');
      config[input.dataset.key] = input.checked;
    });

    return config;
  }

  static loadConfig() {
    let config = JSON.parse(localStorage.getItem(ColumnSelectorController.configKey));
    if (config === null) config = {};
    return config;
  }
}

ColumnSelectorController.targets = [
  'selectorMenu',
  'stdMain',
];

export default ColumnSelectorController;
