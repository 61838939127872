import ApplicationController from './support/application_controller';

/**
 * One-Off Notification controller
 */
class OneOffNotificationController extends ApplicationController {
  connect() {
    this.hideMainForm();
    this.showNavigationButtons();
    this.onTemplateChange();
    this.selectedTemplateId = this.templateIdSelectorTarget.value;
  }

  loadPreviewLink(templateId) {
    fetch(`/city/one_off_notification_templates/${templateId}/preview_link`,
      {
        credentials: 'same-origin',
      })
      .then(response => response.text()).then((html) => {
        this.previewLinkTarget.innerHTML = html;
      });
  }

  showSendNow() {
    this.hideNavigationButtons();
    this.showSendNowForm();
  }

  showSendLater() {
    this.hideNavigationButtons();
    this.showSendLaterForm();
  }

  sendNow(e) {
    this.send(e, 'now');
  }

  sendLater(e) {
    if (this.isSendAtPresent() === true) {
      this.send(e, 'later');
      this.hideMissingSendAtError();
    } else {
      e.preventDefault();
      this.showMissingSendAtError();
    }
  }

  onTemplateChange() {
    this.selectedTemplateId = this.templateIdSelectorTarget.value;
    this.loadPreviewLink(this.selectedTemplateId);
  }

  send(event, when) {
    if (this.isTemplateIdPresent() === true) {
      this.sendFormData(when);
      this.hideMissingTemplateIdError();
      OneOffNotificationController.modalForm().modal('hide');
    } else {
      event.preventDefault();
      this.showMissingTemplateIdError();
    }
  }

  combineFormData(when) {
    const searchData = new FormData(this.searchForm());
    searchData.append('one_off_notification[template_id]', this.templateIdSelectorTarget.value);
    searchData.append('one_off_notification[time_zone]', this.timeZoneSelectorTarget.value);
    searchData.append('one_off_notification[send_at]', this.sendAtInputTarget.value);
    searchData.append('one_off_notification[when]', when);
    return searchData;
  }

  searchForm() {
    if (this.data.get('type') === 'establishments') {
      return OneOffNotificationController.establishmentSearchData();
    }
    return OneOffNotificationController.providerSearchData();
  }

  sendFormData(when) {
    const formData = this.combineFormData(when);
    const request = new XMLHttpRequest();
    request.open('POST', `/city/${this.data.get('type')}/one_off_notifications`);
    request.send(formData);
  }

  isTemplateIdPresent() {
    if (this.templateIdSelectorTarget.value === '') {
      return false;
    }
    return true;
  }

  isSendAtPresent() {
    if (this.sendAtInputTarget.value === '') {
      return false;
    }
    return true;
  }

  showMissingTemplateIdError() {
    $(this.templateIdErrorTarget).css('display', 'block');
  }

  hideMissingTemplateIdError() {
    $(this.templateIdErrorTarget).css('display', 'none');
  }

  showMissingSendAtError() {
    $(this.sendAtErrorTarget).css('display', 'block');
  }

  hideMissingSendAtError() {
    $(this.sendAtErrorTarget).css('display', 'none');
  }

  showNavigationButtons() {
    $(this.navigationButtonsTarget).css('display', 'block');
  }

  hideNavigationButtons() {
    $(this.navigationButtonsTarget).css('display', 'none');
  }

  hideMainForm() {
    $(this.templateIdWrapperTarget).css('display', 'none');
    $(this.sendAtWrapperTarget).css('display', 'none');
    $(this.timeZoneSelectorWrapperTarget).css('display', 'none');
    $(this.submitButtonsWrapperTarget).css('cssText', 'display: none !important');
  }

  showSendNowForm() {
    $(this.templateIdWrapperTarget).css('display', 'block');
    $(this.timeZoneSelectorWrapperTarget).css('display', 'block');
    $(this.submitButtonsWrapperTarget).css('cssText', 'display: flex !important');
    $(this.sendLaterTarget).css('display', 'none');
  }

  showSendLaterForm() {
    $(this.templateIdWrapperTarget).css('display', 'block');
    $(this.sendAtWrapperTarget).css('display', 'block');
    $(this.timeZoneSelectorWrapperTarget).css('display', 'block');
    $(this.submitButtonsWrapperTarget).css('cssText', 'display: flex !important');
    $(this.sendNowTarget).css('display', 'none');
  }

  static establishmentSearchData() {
    return $('form#establishment_search')[0];
  }

  static providerSearchData() {
    return $('form#provider_search')[0];
  }

  static modalForm() {
    return $('.js-new-form-modal');
  }

  static closeIcon() {
    return $('.close');
  }

  previewTemplate() {
    const templateId = this.templateIdSelectorTarget.value;

    fetch(`/city/one_off_notification_templates/${templateId}/preview`)
      .then(response => response.text())
      .then((html) => {
        this.oldWindow = this.mainWindowTarget.innerHTML;
        this.mainWindowTarget.innerHTML = html;
        OneOffNotificationController.closeIcon().hide();
      });
  }

  backButton() {
    this.mainWindowTarget.innerHTML = this.oldWindow;
    OneOffNotificationController.closeIcon().show();
    this.templateIdSelectorTarget.value = this.selectedTemplateId;
  }
}

OneOffNotificationController.targets = ['navigationButtons', 'templateIdWrapper',
  'templateIdSelector', 'templateIdError',
  'sendAtWrapper', 'sendAtInput',
  'sendAtError', 'timeZoneSelectorWrapper',
  'timeZoneSelector', 'submitButtonsWrapper',
  'sendNow', 'sendLater', 'previewLink', 'mainWindow'];

export default OneOffNotificationController;
