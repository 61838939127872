import { Controller } from 'stimulus';

/**
 * Add Dynamic File Field To Form
 */
class DynamicFileFieldsController extends Controller {
  addField(event) {
    event.preventDefault();
    const templateClone = this.templateTarget.cloneNode(true);
    this.inputsTarget.append(templateClone.content);
  }

  removeField(event) {
    event.preventDefault();

    const fileInputs = Array.from(this.inputsTarget.children);
    const index = fileInputs.indexOf(event.target.parentNode);
    this.inputsTarget.children[index].remove();
  }
}

DynamicFileFieldsController.targets = ['inputs', 'template'];

export default DynamicFileFieldsController;
