import { Controller } from 'stimulus';

class EditWasteTypesController extends Controller {
  connect() {
    const wrapperClass = 'js-waste-type-inputs-wrapper';
    [this.wasteTypesInputsWrapper] = document.getElementsByClassName(wrapperClass);
  }

  addWasteType() {
    const newRow = this.rowContainerTarget.cloneNode(true);

    const nameInput = newRow.querySelector("input[name='waste_types[][name]']");
    const idInput = newRow.querySelector("input[name='waste_types[][id]']");

    // Empty values for new inputs
    nameInput.value = '';
    nameInput.setAttribute('value', '');

    idInput.value = '';
    idInput.setAttribute('value', '');

    this.wasteTypesInputsWrapper.appendChild(newRow);
  }

  removeWasteType() {
    const destroyInput = this.rowContainerTarget.querySelector("input[name='waste_types[][_destroy]']");

    // Mark waste type for deletion
    destroyInput.value = '1';
    destroyInput.setAttribute('value', '1');

    // Hide "deleted" waste type row
    this.rowContainerTarget.style.display = 'none';
  }
}

EditWasteTypesController.targets = ['rowContainer'];

export default EditWasteTypesController;
