import flatpickr from 'flatpickr';
import ApplicationController from '../support/application_controller';

/**
 * Control for dates with flatpickr
 */
class FilterController extends ApplicationController {
  initialize() {
    if (sessionStorage.getItem('defaultFromDate')) {
      this.defaultFromDate = sessionStorage.getItem('defaultFromDate');
    } else {
      const now = new Date();
      now.setMonth(now.getMonth() - 6);
      this.defaultFromDate = now;
    }
    if (sessionStorage.getItem('defaultToDate')) {
      this.defaultToDate = sessionStorage.getItem('defaultToDate');
    } else {
      this.defaultToDate = 'today';
    }
  }

  removeExistingFlatpickrInstances() {
    const fromParentNode = this.fromTarget.parentNode;
    const toParentNode = this.toTarget.parentNode;

    const fromInstance = fromParentNode.querySelector('.js-flatpickr-input');
    const toInstance = toParentNode.querySelector('.js-flatpickr-input');

    if (fromInstance) { fromInstance.remove(); }
    if (toInstance) { toInstance.remove(); }
  }

  connect() {
    this.removeExistingFlatpickrInstances();

    this.from = flatpickr(this.fromTarget, {
      altInput: true,
      altInputClass: 'js-flatpickr-input',
      altFormat: this.data.get('format'),
      dateFormat: 'Y-m-d',
      defaultDate: this.defaultFromDate,
      maxDate: 'today',
      onChange: (selectedDates, dateStr) => {
        sessionStorage.setItem('defaultFromDate', dateStr);
      },
    });
    this.to = flatpickr(this.toTarget, {
      altInput: true,
      altInputClass: 'js-flatpickr-input',
      altFormat: this.data.get('format'),
      dateFormat: 'Y-m-d',
      defaultDate: this.defaultToDate,
      maxDate: 'today',
      onChange: (selectedDates, dateStr) => {
        sessionStorage.setItem('defaultToDate', dateStr);
      },
    });
  }

  refresh() {
    this.childTargets.forEach((el) => {
      const child = el;
      child.dataset.from = this.fromTarget.value;
      child.dataset.to = this.toTarget.value;
    });
    this.getControllerByIdentifier('dashboard--counters').render();
    this.getControllerByIdentifier('dashboard--compliance-chart').render();
    this.getControllerByIdentifier('dashboard--inspections-chart').render();
  }
}

FilterController.targets = ['from', 'to', 'child'];

export default FilterController;
