import ApplicationController from '../support/application_controller';

/**
 * Control adding and removing options
 */
class AddController extends ApplicationController {
  /**
   * Add an option
   */
  add() {
    this.element.insertAdjacentHTML('beforeend', this.data.get('html'));
  }
}

export default AddController;
