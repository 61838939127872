import { Controller } from 'stimulus';

/**
 * Search provider contacts by name
 */
class ProviderContactSearchController extends Controller {
  initialize() {
    this.options = [];
    this.selectedValues = this.element.value.split(' ');
    this.$element = $(this.element);
  }

  connect() {
    this.select = this.init();
  }

  disconnect() {
    this.$element[0].selectize.destroy();
  }

  async init() {
    if (this.element.value !== '') {
      this.options = await this.fetchOptions();
    }

    this.$element.selectize({
      valueField: 'id',
      labelField: 'full_name',
      searchField: ['full_name', 'main_email'],
      items: this.selectedValues,
      options: this.options,
      maxItems: 20,
      create: false,
      render: {
        item: (item, escape) => {
          let email;
          if (item.main_email) {
            email = escape(item.main_email);
          } else {
            email = 'No main E-mail';
          }
          return `<div class="d-flex flex-column"><div>${escape(item.full_name)}</div><small>${email}</small></div>`;
        },
        option: (item, escape) => {
          let email;
          if (item.main_email) {
            email = escape(item.main_email);
          } else {
            email = 'No main E-mail';
          }
          return `<div class="list-group-item p-2 d-flex flex-column"><div>${escape(item.full_name)}</div><small>${email}</small></div>`;
        },
      },
      load: this.fetchData.bind(this),
    });
  }

  static async promiseSerial(funcs) {
    return funcs.reduce((p, f) => p.then(r => f().then(Array.prototype.concat.bind(r))),
      Promise.resolve([]));
  }

  static fetchProviderContact(url) {
    return fetch(url, { credentials: 'same-origin' })
      .then(response => response.json()).then(json => json.contact);
  }

  async fetchOptions() {
    const urls = this.selectedValues.map(id => `/city/provider_contacts/${id}.json`);
    const funcs = urls.map(url => () => ProviderContactSearchController.fetchProviderContact(url));

    return ProviderContactSearchController.promiseSerial(funcs).then(result => result);
  }

  fetchData(query, callback) {
    this.query = query;
    if (!query.length || query.length < 2) return callback();
    return fetch(`/city/provider_contacts.json?${encodeURIComponent('q[full_name_cont]')}=${encodeURIComponent(query)}`, { credentials: 'same-origin' })
      .then(response => response.json())
      .then((data) => {
        callback(data.contacts);
      });
  }
}

export default ProviderContactSearchController;
