import { Controller } from 'stimulus';
import BootstrapModalAdapter from '../adapters/bootstrap_modal_adapter';

class EditEstablishmentsInspectorController extends Controller {
  connect() {
    this.modal = new BootstrapModalAdapter();

    this.editButtonTarget.addEventListener('click', (e) => {
      e.preventDefault();

      this.setupModal();
      this.modal.open();
    });
  }

  setupModal() {
    this.modal.setTitle(this.modalTitle);

    fetch('/city/establishments/inspector-select-template')
      .then(response => response.text())
      .then((data) => { this.modal.setBody(data); });
  }

  get modalTitle() {
    return this.editButtonTarget.dataset.modalTitleText;
  }
}

EditEstablishmentsInspectorController.targets = ['editButton'];

export default EditEstablishmentsInspectorController;
