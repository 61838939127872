import ApplicationController from '../support/application_controller';

/**
 * Control adding and removing questions
 */
class MangeController extends ApplicationController {
  initialize() {
    this.selectType();
  }

  /**
   * Add a question
   */
  add() {
    this.insertContent('afterend');
  }

  fetchData() {
    return fetch(`${this.data.get('url')}?position=${new Date().getTime()}`, { credentials: 'same-origin' })
      .then(response => response.text());
  }

  /**
   * Remove question
   */
  remove() {
    const closest = this.element.closest('.js-questions');
    if (closest.children.length === 1) {
      this.questionTarget.value = '';
    } else {
      this.element.remove();
    }
  }

  /**
   * Show options input for select fields
   */
  selectType() {
    this.triggerTypeChange();

    const data = { options: this.data.get('options') };

    return fetch(`/question_types/${this.selectedType}/new?position=${this.position}`,
      {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(data),
      })
      .then(response => response.text()).then((html) => {
        this.bodyTarget.innerHTML = html;

        if (this.selectedType === 'fixed_text') {
          this.fixedText().value = this.data.get('fixed-text');
          this.trixText().editor.insertHTML(this.data.get('fixed-text'));
        }
      });
  }

  get selectedType() {
    return this.typeTarget.value;
  }

  get position() {
    return parseInt(this.data.get('position'), 10);
  }

  triggerTypeChange() {
    if (this.typeTarget.value === 'fixed_text') {
      MangeController.hideItem(this.conditions());
      MangeController.hideItem(this.questionTitle());
    } else {
      MangeController.showItem(this.conditions());
      MangeController.showItem(this.questionTitle());
      const conditionals = this.bodyTarget.parentElement.parentElement.querySelectorAll('.question-conditional-checkbox input[type=checkbox]');
      Array.from(conditionals).forEach((checkbox) => {
        checkbox.dispatchEvent(new Event('typeChange'));
      });
    }
  }

  conditions() {
    return document.querySelector(`#questions_${this.position}_conditions`);
  }

  questionTitle() {
    return document.querySelector(`#questions_${this.position}_question`);
  }

  questionId() {
    return this.questionTarget.id.split('_')[1];
  }

  fixedText() {
    return document.querySelector(`#questions_${this.position}_text`);
  }

  trixText() {
    return document.querySelector(
      `trix-editor[input=questions_${this.position}_text]`,
    );
  }

  /* eslint no-param-reassign: ["error",
                                { "props": true,
                                  "ignorePropertyModificationsFor": ["element"]
                                }] */

  static hideItem(element) {
    element.style.display = 'none';
  }

  static showItem(element) {
    element.style.display = 'block';
  }
}

MangeController.targets = ['question', 'type', 'body'];

export default MangeController;
