import ApplicationController from '../support/application_controller';

/**
 * Show grease traps select box when gt connected
 */
class GreaseTrapsController extends ApplicationController {
  initialize() {
    this.refresh();
  }

  /**
   * Check value of gt_connect and show gt selector if there is a gt connected or field has error
   */
  refresh() {
    if (this.isConnected() || this.hasErrors()) {
      this.selectorTarget.classList.remove('d-none');
    } else {
      this.selectorTarget.classList.add('d-none');
    }
  }

  /**
   *
   * @returns {boolean} true if user selected that a gt is connected
   */
  isConnected() {
    return this.masterTarget.value === 'has_gt';
  }

  /**
   *
   * @returns {boolean} true if grease traps selector has errors and needs to be shown
   */
  hasErrors() {
    return this.selectorTarget.classList.contains('form-group-invalid');
  }
}

GreaseTrapsController.targets = ['master', 'selector'];

export default GreaseTrapsController;
