import ApplicationController from '../support/application_controller';

/**
 * Control adding and removing question groups
 */
class GroupsManageController extends ApplicationController {
  initialize() {
    if (this.element.closest('.group').lastElementChild !== this.element) {
      this.questionsTarget.querySelector('.add-button').classList.add('d-none');
    }
  }

  /**
   * Add a question group
   */
  add() {
    this.fetchData()
      .then((html) => {
        this.element.closest('.group').insertAdjacentHTML('beforeend', html);
      });
    this.questionsTarget.querySelector('.add-button').classList.add('d-none');
  }

  fetchData() {
    return fetch(this.fetchUrl(), { credentials: 'same-origin' })
      .then(response => response.text());
  }

  /**
   * Remove question group
   */
  remove() {
    const group = this.element.closest('.group');
    if (group.querySelectorAll('.group-questions').length > 1) {
      this.element.remove();
    }
    const lastButton = group.lastElementChild.querySelector('.add-button');
    if (Object.prototype.hasOwnProperty.call(lastButton, '.d-none') === false) {
      lastButton.classList.remove('d-none');
    }
  }

  groupId() {
    return this.questionsTarget.dataset.groupId;
  }

  templateId() {
    return this.questionsTarget.dataset.templateId;
  }

  fetchUrl() {
    return `/providers/permit_templates/${this.templateId()}/group_questions?group_id=${this.groupId()}&position=${new Date().getTime()}`;
  }
}

GroupsManageController.targets = ['questions'];

export default GroupsManageController;
