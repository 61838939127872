import ApplicationController from '../support/application_controller';

/**
 * Display pump totals on dashboard
 */
class CountersController extends ApplicationController {
  connect() {
    this.checkForSessionStorageDates();

    this.render();
  }

  checkForSessionStorageDates() {
    if (sessionStorage.getItem('defaultFromDate')) {
      this.element.dataset.from = sessionStorage.getItem('defaultFromDate');
    }

    if (sessionStorage.getItem('defaultToDate')) {
      this.element.dataset.to = sessionStorage.getItem('defaultToDate');
    }
  }

  render() {
    this.counterTargets.forEach((el) => {
      const counter = el;
      const counterType = counter.dataset.type;
      const { from, to } = this.element.dataset;

      fetch(
        `/city/counters/dated/${counterType}/${from}/${to}.html`,
      ).then(response => response.text()).then((html) => {
        counter.innerHTML = html;
      });
    });
  }
}

CountersController.targets = ['counter'];

export default CountersController;
