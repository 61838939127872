import ApplicationController from '../support/application_controller';

/**
 * Dynamic Date Filter by column
 */
class FrequencyController extends ApplicationController {
  trackFrequencyUnit() {
    const frequencyUnits = ['daily', 'weekly', 'monthly', 'yearly'];

    if (frequencyUnits.includes(this.frequencyUnitFieldTarget.value)) {
      const optionsList = FrequencyController.frequencyMap(this.frequencyUnitFieldTarget.value);
      const selectOptions = FrequencyController.buildOptions(optionsList);

      $(this.frequencyFieldTarget).children().html(selectOptions);
      this.frequencyFieldTarget.classList.remove('d-none');
    } else {
      this.frequencyFieldTarget.classList.add('d-none');
    }
  }

  static buildOptions(optionList) {
    let options = '';

    Object.keys(optionList).forEach((key) => {
      options += `<option value="${key}">${optionList[key]}</option>`;
    });
    return options;
  }

  static frequencyMap(frequencyUnit) {
    const mapping = {
      daily: FrequencyController.hoursOptions(),
      weekly: FrequencyController.weeksOptions(),
      monthly: FrequencyController.daysOptions(),
      yearly: FrequencyController.monthsOptions(),
    };
    return mapping[frequencyUnit];
  }

  static hoursOptions() {
    const hours = {};
    for (let i = 0; i < 24; i += 1) {
      hours[i] = `${(i < 10 ? '0' : '') + i}:00`;
    }
    return hours;
  }

  static weeksOptions() {
    const weeks = {};
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    for (let i = 1; i < weekDays.length; i += 1) {
      weeks[i] = weekDays[i];
    }
    return weeks;
  }

  static daysOptions() {
    const days = {};
    for (let i = 1; i <= 28; i += 1) {
      days[i] = i;
    }
    return days;
  }

  static monthsOptions() {
    const months = {};
    const yearMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'];

    for (let i = 0; i < yearMonths.length; i += 1) {
      months[i + 1] = yearMonths[i];
    }
    return months;
  }
}

FrequencyController.targets = ['frequencyUnitField', 'frequencyField'];

export default FrequencyController;
