import { Controller } from 'stimulus';

/**
 * Establishment properties field type controller
 */
class RemoveFilterController extends Controller {
  /**
   * Show options input for select fields
   */
  remove() {
    this.element.remove();
  }
}

export default RemoveFilterController;
