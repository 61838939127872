import { Controller } from 'stimulus';

/**
 * Auto populate based on choice
 */
class AutoCompleteController extends Controller {
  setFields(event) {
    if (event.target.value === '') {
      return;
    }

    this.establishmentNameTarget.value = this.establishmentTarget.dataset.name;
    this.establishmentPostalAddressTarget.value = this.establishmentTarget.dataset.businessAddress;
    this.setGreaseTrapField();
  }

  setGreaseTrapField() {
    this.fetchField((data) => {
      this.establishmentGreaseTrapTarget.innerHTML = data;
    });
  }

  fetchField(callback) {
    return fetch(`${this.establishmentTarget.dataset.item_path}/pump_outs/grease_trap_field`)
      .then(response => response.text()).then((data) => {
        callback(data);
      });
  }
}

AutoCompleteController.targets = [
  'establishment',
  'establishmentName',
  'establishmentPostalAddress',
  'establishmentGreaseTrap',
];

export default AutoCompleteController;
