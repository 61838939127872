import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';

/**
 * Control for dates with flatpickr
 */
class FlatpickrController extends Controller {
  connect() {
    const { parentNode } = this.element;
    const pickrInstance = parentNode.querySelector(
      `.${this.constructor.altInputClass()}`,
    );

    if (pickrInstance) { pickrInstance.remove(); }

    flatpickr(this.element, {
      maxDate: this.data.get('max-date'),
      minDate: this.data.get('min-date'),
      altInput: true,
      altInputClass: this.constructor.altInputClass(),
      altFormat: this.data.get('format'),
      enableTime: this.isTimePicker(),
      dateFormat: this.dateFormat(),
    });
  }

  isTimePicker() {
    return this.data.get('time') === 'true';
  }

  dateFormat() {
    if (this.isTimePicker()) {
      return 'Y-m-d H:i';
    }
    return 'Y-m-d';
  }

  static altInputClass() {
    return 'js-flatpickr-input';
  }
}

export default FlatpickrController;
