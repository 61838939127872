import ApplicationController from '../support/application_controller';

/**
 * Control question conditions
 */
class ConditionsController extends ApplicationController {
  initialize() {
    // current question position
    this.position = parseInt(this.conditionalTarget
      .closest('[data-questions--manage-position]')
      .dataset['questions-ManagePosition'],
    10);
    this.typeChanged();
    // wait for question to load(by another controller)... any idea how to do it without timeout?
    setTimeout(() => {
      this.toggle();
    }, 1000);
  }

  toggle() {
    if (this.conditionalTarget.checked === true) {
      this.showMenu();
      this.populateQuestions();
      this.addAnswers();
      this.disableRequired();
    } else {
      this.hideMenu();
      this.enableRequired();
    }
  }

  typeChanged() {
    if (this.questionsWithPredefinedAnswers().length > 0) {
      this.enableConditional();
    } else {
      this.disableConditional();
    }
  }

  populateQuestions() {
    const questionValue = this.conditionalQuestionSelect().value;
    this.clearQuestionsSelect();
    this.questionsWithPredefinedAnswers().forEach((question) => {
      const option = document.createElement('option');
      option.text = question.querySelector('input').value;
      option.value = question.dataset['questions-ManagePosition'];
      this.conditionalQuestionSelect().add(option);
    });
    this.conditionalQuestionSelect().value = questionValue;
  }

  populateAnswers(answers) {
    const answerValue = this.conditionalAnswerSelect().value;
    this.clearAnswersSelect();
    answers.forEach((answer) => {
      const option = document.createElement('option');
      option.text = answer;
      option.value = answer;
      this.conditionalAnswerSelect().add(option);
    });
    this.conditionalAnswerSelect().value = answerValue;
  }

  addAnswers() {
    const selectedQuestionPosition = this.conditionalQuestionSelect().value;
    if (selectedQuestionPosition === '') return;
    const selectedQuestion = ConditionsController.otherQuestion(selectedQuestionPosition);
    const answers = Array.from(selectedQuestion.querySelector('.js-options').children)
      .map(el => el.querySelector('input').value);
    this.populateAnswers(answers);
    ConditionsController.showItem(this.conditionalAnswerSelect());
  }

  showMenu() {
    this.conditionalFields().slice(0, 2).forEach((element) => {
      ConditionsController.showItem(element);
    });
  }

  hideMenu() {
    this.conditionalFields().forEach((element) => {
      ConditionsController.hideItem(element);
    });
  }

  currentQuestion() {
    return document.querySelector(`[data-questions--manage-position='${this.position}']`);
  }

  static otherQuestion(position) {
    return document.querySelector(`[data-questions--manage-position='${position}']`);
  }

  static allQuestions() {
    return document.querySelector('.js-questions').children;
  }

  previousQuestions() {
    const arrayOfQuestions = Array.from(ConditionsController.allQuestions());
    const indexOfCurrentQuestion = arrayOfQuestions.indexOf(this.currentQuestion());
    return arrayOfQuestions.slice(0, indexOfCurrentQuestion);
  }

  // returns questions with valid type
  questionsWithPredefinedAnswers() {
    return this.previousQuestions().filter(el => ConditionsController.predefinedTypes().includes(el.querySelector(`#questions_${el.dataset['questions-ManagePosition']}_type`).value));
  }

  static predefinedTypes() {
    return ['checkboxes', 'dropdown', 'multiple'];
  }

  clearQuestionsSelect() {
    const questionSelect = this.conditionalQuestionSelect();
    while (questionSelect.length > 0) {
      questionSelect.remove(0);
    }
  }

  clearAnswersSelect() {
    const answersSelect = this.conditionalAnswerSelect();
    while (answersSelect.length > 0) {
      answersSelect.remove(0);
    }
  }

  disableRequired() {
    this.requiredCheckBox().checked = false;
    this.requiredCheckBox().disabled = true;
  }

  enableRequired() {
    this.requiredCheckBox().disabled = false;
  }

  disableConditional() {
    this.conditionalCheckBox().checked = false;
    this.conditionalCheckBox().disabled = true;
    this.hideMenu();
    this.enableRequired();
  }

  enableConditional() {
    this.conditionalCheckBox().disabled = false;
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/style
  /* eslint no-param-reassign: ["error",
                                { "props": true,
                                  "ignorePropertyModificationsFor": ["element"]
                                }] */
  static hideItem(element) {
    element.style.display = 'none';
  }

  static showItem(element) {
    element.style.display = 'block';
  }

  conditionalFields() {
    return [this.conditionalTypeSelect(),
      this.conditionalQuestionSelect(),
      this.conditionalAnswerSelect()];
  }

  conditionalTypeSelect() {
    return this.currentQuestion().querySelector('.conditional-type select');
  }

  conditionalQuestionSelect() {
    return this.currentQuestion().querySelector('.conditional-question select');
  }

  conditionalAnswerSelect() {
    return this.currentQuestion().querySelector('.conditional-answer select');
  }

  requiredCheckBox() {
    return document.querySelector(`#questions_${this.position}_required`);
  }

  conditionalCheckBox() {
    return document.querySelector(`#questions_${this.position}_conditional`);
  }
}

ConditionsController.targets = ['conditional'];

export default ConditionsController;
