/* global google */
import ApplicationController from './support/application_controller';

/**
 * Control adding and removing address
 * Adds google autocomplete to addresses
 */
class PostalAddressController extends ApplicationController {
  connect() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget);
    this.autocomplete.setComponentRestrictions({
      country: [this.data.get('country')],
    });
    this.autocomplete.addListener('place_changed', this.fillInAddress.bind(this));
  }

  /**
   *
   * @param value
   */
  set place(value) {
    this.formattedTarget.value = value.formatted_address;
    this.latitudeTarget.value = value.geometry.location.lat();
    this.longitudeTarget.value = value.geometry.location.lng();
  }

  /**
   * get place
   */
  fillInAddress() {
    this.place = this.autocomplete.getPlace();
  }
}

PostalAddressController.targets = ['address', 'formatted', 'latitude', 'longitude'];

export default PostalAddressController;
