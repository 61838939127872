import { Controller } from 'stimulus';

/**
 * handles trix editor events for enforcement layout
 */
class EnforcementLayoutController extends Controller {
  connect() {
    // drag&drop event
    this.element.addEventListener('trix-file-accept', (event) => {
      EnforcementLayoutController.acceptFile(event);
    });

    this.element.addEventListener('trix-attachment-add', (event) => {
      EnforcementLayoutController.disableEditing(event.target);
    });

    this.element.addEventListener('trix-attachment-remove', (event) => {
      EnforcementLayoutController.enableEditing(event.target);
    });

    EnforcementLayoutController.toggleAttachmentButton(this.element);

    this.element.addEventListener('trix-change', (event) => {
      EnforcementLayoutController.toggleAttachmentButton(event.target);
    });
  }

  static toggleAttachmentButton(button) {
    if (EnforcementLayoutController.isAttachmentPresent(button.value)) {
      EnforcementLayoutController.hideAttachmentButton(button);
    } else {
      EnforcementLayoutController.showAttachmentButton(button);
    }
  }

  static acceptFile(event) {
    // prevent adding file if content present
    if (EnforcementLayoutController.isAttachmentPresent(event.target.value)) {
      event.preventDefault();
    }
  }

  static isAttachmentPresent(value) {
    return value.includes('data-trix-attachment');
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/style
  /* eslint no-param-reassign: ["error",
                                { "props": true,
                                  "ignorePropertyModificationsFor": ["target"]
                                }] */

  static disableEditing(target) {
    target.contentEditable = false;
  }

  static enableEditing(target) {
    target.contentEditable = true;
  }

  static hideAttachmentButton(target) {
    $(target.previousSibling).find('button.trix-button--icon-attach').parent().hide();
  }

  static showAttachmentButton(target) {
    $(target.previousSibling).find('button.trix-button--icon-attach').parent().show();
  }
}

export default EnforcementLayoutController;
