import { Controller } from 'stimulus';

class PercentCalculatorController extends Controller {
  static componentValuesSum(components) {
    const componentValues = components.map(component => parseFloat(component.value, 10));

    const sumReducer = (previousValue, currentValue) => previousValue + currentValue;
    const initialSumValue = 0;
    const valuesSum = componentValues.reduce(sumReducer, initialSumValue);

    return valuesSum;
  }

  allComponentValuesSum() {
    return PercentCalculatorController.componentValuesSum(this.componentInputTargets);
  }

  filterComponentsToCalculate() {
    return this.componentInputTargets.filter(component => component.dataset.calculate === 'true');
  }

  markResultInputAsSuccess() {
    this.resultInputTarget.classList.remove('text-danger');
    this.resultInputTarget.classList.add('text-success');
  }

  markResultInputAsFailure() {
    this.resultInputTarget.classList.remove('text-success');
    this.resultInputTarget.classList.add('text-danger');
  }

  updateResultColor() {
    if (this.resultInputTarget.value >= 25) {
      this.markResultInputAsFailure();
    } else {
      this.markResultInputAsSuccess();
    }
  }

  updateTotal() {
    let total = this.totalInputTarget.value;

    // If no total input has no value - calculate and update it by summing
    //   components.
    if (!total) {
      total = PercentCalculatorController.componentValuesSum(this.componentInputTargets);

      if (!Number.isNaN(total)) { this.totalInputTarget.value = total; }
    }
  }

  updateResult(value) {
    this.resultInputTarget.value = value;
    this.updateResultColor();
  }

  static isResultValid(value) {
    const isNaN = Number.isNaN(value);
    const isStringNaN = value === 'NaN';

    return !isNaN && !isStringNaN;
  }

  static calculateResult({ componentsSum = 0, total = 0 }) {
    return (componentsSum * 100 / total).toFixed(2);
  }

  calculate() {
    this.updateTotal();

    // Calculate result
    const total = this.totalInputTarget.value;
    const calculatableComponentsSum = PercentCalculatorController.componentValuesSum(
      this.filterComponentsToCalculate(),
    );
    const result = PercentCalculatorController.calculateResult({
      total,
      componentsSum: calculatableComponentsSum,
    });

    // Don't update result input if result is not valid
    if (PercentCalculatorController.isResultValid(result)) {
      this.updateResult(result);
    }
  }

  connect() { this.updateResultColor(); }
}

PercentCalculatorController.targets = [
  'totalInput',
  'resultInput',
  'componentInput',
];

export default PercentCalculatorController;
