import { Controller } from 'stimulus';

/**
 * Dislay List of Field Configurations
 */
class ListController extends Controller {
  initialize() {
    this.loadFieldConfigurations();
  }

  loadFieldConfigurations() {
    return fetch(`/field_configurations.js?owner_type=${this.data.get('owner-type')}`, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
      .then(response => response.text())
      .then((data) => {
        this.fieldListTarget.innerHTML = data;
      });
  }
}

ListController.targets = ['fieldList'];

export default ListController;
