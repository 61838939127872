import { Controller } from 'stimulus';

/**
 * Establishment properties field type controller
 */
class FieldTypeController extends Controller {
  initialize() {
    this.select();
  }

  /**
   * Show options input for select fields
   */
  select() {
    if (this.fieldTypeTarget.value === 'select_box') {
      this.fieldOptionsTargets.forEach((el) => {
        el.classList.remove('d-none');
      });
    } else {
      this.fieldOptionsTargets.forEach((el) => {
        el.classList.add('d-none');
      });
    }
  }
}

FieldTypeController.targets = ['fieldOptions', 'fieldType'];

export default FieldTypeController;
