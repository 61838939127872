import { Controller } from 'stimulus';

class VehicleSelectController extends Controller {
  connect() {
    if (this.hasVehicleSelectTarget) {
      this.form = this.vehicleSelectTarget.closest('form');

      this.form.addEventListener(
        'change',
        this.updateVehicleSelect.bind(this),
      );
    }
  }

  updateVehicleSelect() {
    const providerId = VehicleSelectController.providerIdFromForm(
      {
        form: this.form,
        recordName: this.vehicleSelectTarget.dataset.recordName,
      },
    );

    // NOTE: Do not update select if provider ID is unknown or it is the same
    //   one.
    if (providerId === '') { return; }
    if (providerId === this.vehicleSelectTarget.dataset.providerId) { return; }

    this.vehicleSelectTarget.dataset.providerId = providerId;

    fetch(
      `/city/pump_outs/provider_vehicles/${providerId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    ).then(response => response.json())
      .then((json) => {
        VehicleSelectController.removeSelectOptions(this.vehicleSelectTarget);

        VehicleSelectController.addSelectOptions(
          { selectNode: this.vehicleSelectTarget, data: json },
        );
      });
  }

  static addSelectOptions({ selectNode, data }) {
    // NOTE: If select is selectized we need to handle options differently.
    if (selectNode.classList.contains('selectized')) {
      data.forEach((vehicle) => {
        selectNode.selectize.addOption(
          { text: vehicle.number, value: vehicle.id },
        );
      });

      selectNode.selectize.refreshOptions();
    } else {
      selectNode.options.add(new Option());

      data.forEach((vehicle) => {
        const newOption = new Option(vehicle.number, vehicle.id);

        selectNode.options.add(newOption);
      });
    }
  }

  static removeSelectOptions(selectNode) {
    // NOTE: If select is selectized we need to handle options differently.
    if (selectNode.classList.contains('selectized')) {
      const optionKeys = Object.keys(selectNode.selectize.options);

      optionKeys.forEach((key) => { selectNode.selectize.removeOption(key); });
      selectNode.selectize.refreshOptions();
    } else {
      const { options } = selectNode;
      let index = options.length;

      for (; index >= 0; index -= 1) {
        options.remove(index);
      }
    }
  }

  static providerIdFromForm({ form, recordName }) {
    const formData = new FormData(form);

    return formData.get(`${recordName}[provider_id]`);
  }
}

VehicleSelectController.targets = ['vehicleSelect'];

export default VehicleSelectController;
