import ApplicationController from './support/application_controller';

/**
 * Scheduled Inspection forms and views controller
 */
class ScheduledInspectionController extends ApplicationController {
  /**
   * Show options input for select fields
   */

  startEdit(event) {
    if (event !== undefined) event.preventDefault();
    if (this.hasShowTarget) $(this.showTarget).hide();
    $(this.formTarget).show();
  }

  stopEdit(event) {
    if (event !== undefined) event.preventDefault();
    if (this.hasShowTarget) $(this.showTarget).show();
    $(this.formTarget).hide();
  }

  connect() {
    if (this.hasShowTarget) this.stopEdit();
    if (this.hasErrorsTarget) this.startEdit();
  }
}

ScheduledInspectionController.targets = [
  'form',
  'show',
  'errors',
];

export default ScheduledInspectionController;
