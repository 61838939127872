import { Controller } from 'stimulus';

/**
 * Control select boxes with selectize.js
 */
class SelectizeController extends Controller {
  initialize() {
    this.$element = $(this.element);
  }

  connect() {
    this.$element.selectize({
      plugins: ['remove_button'],
      persist: false,
      closeAfterSelect: true,
      create: this.data.get('create'),
    });
  }

  disconnect() {
    this.$element[0].selectize.destroy();
  }
}

export default SelectizeController;
