import { Controller } from 'stimulus';

/**
 * Dynamic Crs Loading
 */
class CrsController extends Controller {
  /* global crs */

  initialize() {
    this.crs = crs;
    this.crs.init();
  }

  connect() {
    if (this.data.get('selectedValue') !== '') {
      this.stateInputTarget.value = this.data.get('selectedValue');
    }
  }
}

CrsController.targets = ['stateInput'];

export default CrsController;
