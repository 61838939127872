import { Controller } from 'stimulus';

/**
 * Select FSE language
 */
class FseLanguageController extends Controller {
  initialize() {
    this.$element = $(this.element);
  }

  connect() {
    return this.init();
  }

  disconnect() {
    this.$element[0].selectize.destroy();
  }

  async init() {
    this.$element.selectize({
      onChange(value) {
        let url = window.location.href;
        const regex = /locale=\w{2}/;
        const match = regex.exec(url);
        if (match != null) {
          window.location.href = url.replace(match[0], `locale=${decodeURIComponent(value)}`);
        } else {
          url += (url.indexOf('?') > -1) ? '&' : '?';
          url += `locale=${decodeURIComponent(value)}`;
          window.location.href = url;
        }
      },
    });
  }
}

export default FseLanguageController;
