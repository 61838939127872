import { Controller } from 'stimulus';

/**
 * Hide expiration date if Does_not_expire checkbox is checked
 */
class ExpirationDateController extends Controller {
  initialize() {
    this.doesNotExpireChanged();
  }

  doesNotExpireChanged() {
    if (this.doesNotExpireTarget.checked === true) {
      this.expiresAtWrapperTarget.style.display = 'none';
    } else {
      this.expiresAtWrapperTarget.style.display = 'block';
    }
  }
}

ExpirationDateController.targets = [
  'expiresAtWrapper', 'doesNotExpire',
];

export default ExpirationDateController;
