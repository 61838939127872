import { Controller } from 'stimulus';

/**
 * Dynamic Date Filter by column
 */
class DateRangeController extends Controller {
  initialize() {
    this.dynamicRanges = ['years', 'months', 'weeks', 'days', 'between'];
  }

  trackFilterType() {
    const inputName = this.filterTypeInputTarget.name.split(/[[\]]{1,2}/)[1];
    const filterType = this.filterTypeInputTarget.value;
    this.showAdditionalFields(filterType, inputName);
  }

  showAdditionalFields(filterType, inputName) {
    if (this.dynamicRanges.includes(filterType)) {
      this.loadAdditionalDateRangeInput(filterType, inputName);
    } else {
      this.additionalDateFieldsTarget.innerHTML = '';
    }
  }

  static fetchData(url, callback) {
    return fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
      .then(response => response.text())
      .then((data) => {
        callback(data);
      });
  }

  loadAdditionalDateRangeInput(range, inputName) {
    return new Promise((() => {
      DateRangeController.fetchData(`date_ranges/fields.js?range=${range}&input_name=${inputName}`, (data) => {
        this.additionalDateFieldsTarget.innerHTML = data;
      });
    }));
  }
}

DateRangeController.targets = ['filterTypeInput', 'additionalDateFields'];

export default DateRangeController;
