import { Controller } from 'stimulus';

/**
 * Toggle on line profile setting
 */
class PublicProfileSettingsController extends Controller {
  toggle() {
    this.optionTargets.forEach((element) => {
      const box = element;
      box.disabled = !this.featureTarget.checked;
    });
  }
}

PublicProfileSettingsController.targets = [
  'feature',
  'option',
];

export default PublicProfileSettingsController;
