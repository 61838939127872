import SignatureButtonController from '../support/signature_button_controller';
/**
 * Show sign window
*/
class SignController extends SignatureButtonController {
  /* global eversign */
  open() {
    return fetch('/custom_property_signs/sign_url', { credentials: 'same-origin' }).then(response => response.json())
      .then((data) => {
        $('#modalTitle').html('Signing Document');
        $('.js-modal-body').html('<div id="signature-frame"></div>');
        $('.js-new-form-modal').modal('show');
        this.spinnerTarget.classList.remove('d-none');
        this.spinnerTarget.classList.add('show');
        this.resultTarget.innerHTML = '';

        eversign.open({
          url: data.sign_url,
          containerID: 'signature-frame',
          width: 460,
          height: 600,
          events: {
            signed: (() => {
              SignController.getSignatureResult(data.document_hash).then((html) => {
                const signatureAttachmentId = $(html).find('#signatureAttachmentId').val();
                const signatureDocumentHash = $(html).find('#signatureDocumentHash').val();

                this.inputFieldTarget.value = `${signatureAttachmentId}|${signatureDocumentHash}`;
                this.resultTarget.innerHTML = html;
                this.spinnerTarget.classList.remove('show');
                this.spinnerTarget.classList.add('d-none');
                $('.js-new-form-modal').modal('hide');
              });
            }),
            declined: (() => {
              this.resultTarget.innerHTML = '<div class="text-success">Sign proceess declined.</div>';
              this.spinnerTarget.classList.remove('show');
              this.spinnerTarget.classList.add('d-none');
              $('.js-new-form-modal').modal('hide');
            }),
          },
        });
      });
  }

  static getSignatureResult(documetHash) {
    return fetch(`/custom_property_signs/signature_attachment/${documetHash}`, { credentials: 'same-origin' }).then(response => response.text());
  }
}

SignController.targets = ['result', 'inputField', 'spinner'];

export default SignController;
