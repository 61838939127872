import ApplicationController from './support/application_controller';

/**
 * Sets browser time zone to time zone selector
 */
class TimeZonesController extends ApplicationController {
  connect() {
    this.setTimeZone();
  }

  setTimeZone() {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
    const date = new Date();
    const timeZone = (date.getTimezoneOffset() / 60) * -1;
    this.timeZoneSelectorTarget.value = TimeZonesController.formattedTimeZone(timeZone);
  }

  static isInt(number) {
    return number % 1 === 0;
  }

  static formattedTimeZone(timeZone) {
    if (TimeZonesController.isInt(timeZone) === true) {
      return TimeZonesController.formattedIntType(timeZone);
    }
    return TimeZonesController.formattedFloatType(timeZone);
  }

  static formattedIntType(number) {
    let timeZone = '';
    timeZone = String(number).concat(':00');
    if (number >= 0) {
      timeZone = '+'.concat(timeZone);
    }
    return timeZone;
  }

  static formattedFloatType(number) {
    let timeZone = '';
    timeZone = String(number).split('.')[0].concat(':30');
    if (number >= 0) {
      timeZone = '+'.concat(timeZone);
    }
    return timeZone;
  }
}

TimeZonesController.targets = ['timeZoneSelector'];

export default TimeZonesController;
