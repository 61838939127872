import { Controller } from 'stimulus';

class PercentCalculatorTemplateController extends Controller {
  connect() {
    [this.componentsContainer] = document.getElementsByClassName('js-components-container');
  }

  buildNewLabel() {
    const labelClone = this.containerLabelTarget.cloneNode(true);

    labelClone.setAttribute('for', this.newInputId);

    return labelClone;
  }

  buildNewInput() {
    const inputClone = this.containerInputTarget.cloneNode();

    inputClone.setAttribute('id', this.newInputId);
    inputClone.setAttribute(
      'name',
      `structure[components][${this.newContainerNumber}][name]`,
    );
    inputClone.value = '';

    return inputClone;
  }

  buildNewCheckbox() {
    const checkboxClone = this.containerCheckboxTarget.cloneNode();

    checkboxClone.setAttribute(
      'id',
      `structure_components_${this.newContainerNumber}_calculate`,
    );
    checkboxClone.setAttribute(
      'name',
      `structure[components][${this.newContainerNumber}][calculate]`,
    );
    checkboxClone.checked = false;

    return checkboxClone;
  }

  addComponent() {
    this.newContainerNumber = Date.now();
    this.newInputId = `structure_components_${this.newContainerNumber}_name`;

    const inputGroupContainerClone = this.inputGroupContainerTarget.cloneNode();

    inputGroupContainerClone.appendChild(this.buildNewLabel());
    inputGroupContainerClone.appendChild(this.buildNewInput());

    const checkboxContainerClone = this.checkboxContainerTarget.cloneNode();
    checkboxContainerClone.appendChild(this.buildNewCheckbox());

    const controlButtonsClone = this.containerControlButtonsTarget.cloneNode(true);

    const containerClone = this.componentContainerTarget.cloneNode();

    containerClone.appendChild(inputGroupContainerClone);
    containerClone.appendChild(checkboxContainerClone);
    containerClone.appendChild(controlButtonsClone);

    this.componentsContainer.appendChild(containerClone);
  }

  removeComponent() {
    this.componentContainerTarget.remove();
  }
}

PercentCalculatorTemplateController.targets = [
  'componentContainer',
  'containerLabel',
  'containerInput',
  'containerCheckbox',
  'containerControlButtons',
  'inputGroupContainer',
  'checkboxContainer',
];

export default PercentCalculatorTemplateController;
