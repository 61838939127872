import ApplicationController from './support/application_controller';

/**
 * Load default content for a profile, establishment, contact or grease trap
 */
class ProfileController extends ApplicationController {
  initialize() {
    this.showCurrent();
  }

  load(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.current = event.target.dataset.url;
  }

  showCurrent() {
    this.buttonTargets.forEach((element) => {
      element.classList.toggle('active', element.dataset.url === this.current);
    });
    this.spinnerTarget.classList.remove('d-none');
    this.spinnerTarget.classList.add('show');
    this.bodyTarget.classList.remove('show');
    fetch(this.current, { credentials: 'same-origin' })
      .then(response => response.text()).then((html) => {
        this.bodyTarget.innerHTML = html;
        this.spinnerTarget.classList.remove('show');
        this.bodyTarget.classList.add('show');
        this.spinnerTarget.classList.add('d-none');
      });
  }

  set current(value) {
    this.data.set('current', value);
    this.showCurrent();
  }

  get current() {
    return this.data.get('current');
  }
}

ProfileController.targets = [
  'button',
  'spinner',
  'body',
];

export default ProfileController;
