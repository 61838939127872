import { Controller } from 'stimulus';

class InspectionAttachmentsValidationController extends Controller {
  static errorNodeClassName() { return 'js-attachment-error'; }

  getExistingErrorNode() {
    const className = InspectionAttachmentsValidationController.errorNodeClassName();

    return this.inputContainerTarget.getElementsByClassName(className)[0];
  }

  addErrorMessage() {
    const errorMessageNode = document.createElement('div');
    const className = InspectionAttachmentsValidationController.errorNodeClassName();

    errorMessageNode.innerText = 'Please upload less than 15MB per save';
    errorMessageNode.classList.add('text-danger');
    errorMessageNode.classList.add(className);

    this.inputContainerTarget.append(errorMessageNode);
  }

  removeExistingErrorMessage() {
    const existingErrorNode = this.getExistingErrorNode();

    if (existingErrorNode) { existingErrorNode.remove(); }
  }

  validateFileSize(event) {
    const input = event.target;
    let { files } = input;
    let totalFilesSize = 0;

    this.removeExistingErrorMessage();

    if (files.length < 1) { return; }

    files = Array.from(files);
    files.forEach((file) => { totalFilesSize += file.size; });

    const totalFilesSizeInMB = totalFilesSize / 1024 / 1024;

    if (totalFilesSizeInMB >= 14) {
      input.value = null;

      this.addErrorMessage();
    }
  }

  connect() {
    this.inputTarget.addEventListener('change', this.validateFileSize.bind(this));
  }
}

InspectionAttachmentsValidationController.targets = [
  'input',
  'errorTarget',
  'inputContainer',
];

export default InspectionAttachmentsValidationController;
