import { Controller } from 'stimulus';

class SearchbarController extends Controller {
  initialize() {
    this.initializePopover();

    this.addEventForSearching();

    this.addEventForUpdatingSearchbar();
  }

  addEventForSearching() {
    this.searchBarTarget.addEventListener('keypress', (event) => {
      const enterKeyCode = 13;
      const isEnterKeyPressed = event.keyCode === enterKeyCode;

      if (isEnterKeyPressed && !this.canSearch()) {
        this.enablePopover();
        this.showPopover();
      }

      if (isEnterKeyPressed && this.canSearch()) {
        this.hidePopover();
        this.disablePopover();

        this.updateFilterValue(event.target.value);

        this.submitSearchForm();
      }
    });
  }

  initializePopover() { $(this.searchBarTarget).popover(); }

  enablePopover() { $(this.searchBarTarget).popover('enable'); }

  disablePopover() { $(this.searchBarTarget).popover('disable'); }

  showPopover() { $(this.searchBarTarget).popover('show'); }

  hidePopover() { $(this.searchBarTarget).popover('hide'); }

  addEventForUpdatingSearchbar() {
    this.filtersFormWrapperTarget.addEventListener('updated', () => {
      this.searchBarTarget.value = this.searchFilterTarget.value;
    });
  }

  updateFilterValue(value) {
    this.searchFilterTarget.value = value;
  }

  submitSearchForm() {
    this.filterFormSubmitTarget.click();
  }

  canSearch() {
    return this.isSearchBarValueExceedsMinLength() || this.isSearchBarHasNoValue();
  }

  isSearchBarHasNoValue() {
    return this.searchBarTarget.value.length === 0;
  }

  isSearchBarValueExceedsMinLength() {
    return this.searchBarTarget.value.length >= this.getMinLength();
  }

  getMinLength() {
    return this.searchFilterTarget.minLength;
  }
}

SearchbarController.targets = [
  'searchBar', 'filterFormSubmit', 'searchFilter', 'filtersFormWrapper',
];

export default SearchbarController;
