import ApplicationController from '../support/application_controller';

/**
 * Add Hyperlink To Questions
 */
class HyperlinkController extends ApplicationController {
  initialize() {
    $(this.formTarget).on('click', (e) => { e.stopPropagation(); });
  }

  add() {
    if (!HyperlinkController.isValidHttpUrl(this.linkTarget.value)) {
      this.badLinkMessageTarget.innerHTML = 'Please enter valid URL with http:// or https:// protocol.';
      return;
    }
    this.questionTarget.value += this.createMarkdownLink();
    $(this.formTarget)[0].classList.remove('show');
  }

  createMarkdownLink() {
    return `[${this.linkTextTarget.value}](${this.linkTarget.value})`;
  }

  static isValidHttpUrl(string) {
    try {
      const url = new URL(string);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (_) {
      return false;
    }
  }
}

HyperlinkController.targets = ['question', 'link', 'linkText', 'form', 'badLinkMessage'];

export default HyperlinkController;
