import Chart from 'chart.js';
import ApplicationController from '../support/application_controller';

/**
 * Chart compliance history
 */
class ComplianceChartController extends ApplicationController {
  initialize() {
    this.localisations = JSON.parse(this.data.get('localisations'));
    this.locale = this.localisations.locale;
  }

  connect() {
    this.chart = new Chart(
      this.bodyTarget,
      {
        type: 'line',
        options: this.chartOptions,
      },
    );

    this.render();
  }

  render() {
    this.fetchData().then((json) => {
      this.json = json;
      this.chart.data = this.chartData;
      this.spinnerTarget.classList.add('d-none');
      this.bodyTarget.classList.add('show');
      this.chart.update();
    });
  }

  fetchData() {
    return fetch(this.url, { credentials: 'same-origin' }).then(response => response.json());
  }

  get url() {
    return `/city/counters/compliance/${this.element.dataset.from}/${this.element.dataset.to}`
           + `/${this.intervalTarget.value}`;
  }

  /**
   * chart.js options
   */
  get chartOptions() {
    return {
      tooltips: { mode: 'index' },
      hover: { mode: 'index' },
      scales: {
        xAxes: [{
          scaleLabel: { display: false },
        }],
        yAxes: [{
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: this.localisations.establishments_no,
          },
        }],
      },
    };
  }

  /**
   * chart.js data
   */
  get chartData() {
    return {
      labels: this.labels,
      datasets: [
        {
          label: this.localisations.never_pumped,
          borderColor: '#9B9B9B',
          backgroundColor: '#9B9B9B',
          data: this.json.results['0'],
        }, {
          label: this.localisations.compliant,
          borderColor: '#B8E986',
          backgroundColor: '#B8E986',
          data: this.json.results['1'],
        }, {
          label: this.localisations.overdue,
          borderColor: '#F8E71C',
          backgroundColor: '#F8E71C',
          data: this.json.results['2'],
        },
        {
          label: this.localisations.non_compliant,
          borderColor: '#FF6C7E',
          backgroundColor: '#FF6C7E',
          data: this.json.results['3'],
        },
        {
          label: this.localisations.no_devices,
          borderColor: '#54A8D2',
          backgroundColor: '#54A8D2',
          data: this.json.results['4'],
        },
      ],
    };
  }

  /**
   *
   * @return Array of localised dates
   */
  get labels() {
    return this.json.labels.map(date => this.localiseDate(date));
  }

  /**
   *
   * @param date Date as a timestamp
   * @return String of localised month, eg. 'February 1, 2018'
   */
  localiseDate(date) {
    return new Date(date).toLocaleDateString(this.localisations.locale);
  }
}

ComplianceChartController.targets = ['spinner', 'body', 'interval'];

export default ComplianceChartController;
