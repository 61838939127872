import { Controller } from 'stimulus';

/**
 * Search providers by name
 */
class ProviderSearchController extends Controller {
  initialize() {
    this.$element = $(this.element);
  }

  connect() {
    return this.init();
  }

  disconnect() {
    this.$element[0].selectize.destroy();
  }

  async init() {
    let option;
    if (this.element.value !== '') {
      option = await this.fetchOption();
    }
    this.$element.selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      options: [option],
      create: false,
      maxItems: this.maxItems || 1,
      render: {
        item: (item, escape) => `<div class="d-flex flex-column"><div>${escape(item.name)}</div></div>`,
        option: (item, escape) => `<div class="list-group-item p-2 d-flex flex-column"><div>${escape(item.name)}</div></div>`,
      },
      load: this.fetchData.bind(this),
      onChange: () => {
        document.getElementsByTagName('form')[0].dispatchEvent(new Event('change'));
      },
    });
  }

  get maxItems() {
    return parseInt(this.data.get('maxItems'), 10);
  }

  get path() {
    return this.data.get('path');
  }

  get showPath() {
    if (this.data.get('showPath')) {
      return this.data.get('showPath');
    }
    return this.path;
  }

  async fetchOption() {
    return fetch(`${this.showPath}/${this.element.value}.json`, { credentials: 'same-origin' })
      .then(response => response.json()).then(json => json.provider);
  }

  fetchData(query, callback) {
    this.query = query;
    if (!query.length || query.length < 2) return callback();
    return fetch(`${this.path}.json?${encodeURIComponent('q[name_cont]')}=${encodeURIComponent(query)}`,
      { credentials: 'same-origin' }).then(response => response.json())
      .then((data) => {
        callback(data.providers);
      });
  }
}

export default ProviderSearchController;
