import SignatureButtonController from '../support/signature_button_controller';
/**
 * Show sign window
 */
class SimpleSignController extends SignatureButtonController {
  open() {
    const $signpadModal = $('.js-new-form-modal');
    const signPadModalNode = $signpadModal[0];

    const handleSuccessfulSign = (result) => {
      if (signPadModalNode.dataset.currentSignId !== this.inputFieldTarget.id) { return; }

      SimpleSignController.fetchSignatureButtonControlsTemplate()
        .then(response => response.text())
        .then((template) => {
          this.resultTarget.innerHTML = `<img src="${result.detail.sign}" class='signature_image'> ${template}`;
        });

      this.inputFieldTarget.value = result.detail.sign;

      $signpadModal.modal('hide');
    };

    signPadModalNode.dataset.currentSignId = this.inputFieldTarget.id;

    return SimpleSignController.fetchSignaturePadModalTemplate()
      .then(response => response.text())
      .then((data) => {
        $('#modalTitle').html('Add Signature');
        $('.js-modal-body').html(data);

        SimpleSignController.resizeCanvas();

        signPadModalNode.addEventListener('signed', handleSuccessfulSign);
      });
  }

  remove() {
    SimpleSignController.fetchCreateSignatureButtonTemplate()
      .then(response => response.text())
      .then((data) => { this.resultTarget.innerHTML = data; });

    this.inputFieldTarget.value = '';
  }

  static resizeCanvas() {
    $('.js-new-form-modal').modal('show').on('shown.bs.modal', () => {
      $('canvas')[0].height = $('canvas')[0].offsetHeight;
      $('canvas')[0].width = $('canvas')[0].offsetWidth;
    });
  }

  static fetchCreateSignatureButtonTemplate() {
    return fetch('/custom_property_simple_signs/create_signature_button');
  }

  static fetchSignaturePadModalTemplate() {
    return fetch('/custom_property_simple_signs/new');
  }

  static fetchSignatureButtonControlsTemplate() {
    return fetch('/custom_property_simple_signs/signature_control_buttons');
  }
}

SimpleSignController.targets = ['result', 'inputField'];

export default SimpleSignController;
