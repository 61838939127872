import ApplicationController from '../support/application_controller';

/**
 * Check date field compatibility and react appropriately
 */
class DateFieldController extends ApplicationController {
  connect() {
    this.checkCompatibility();
  }

  checkCompatibility() {
    if (this.nativeTarget.type === 'text') {
      this.fallback = true;
      this.nativeTargets.forEach((el) => {
        el.remove();
      });
    } else {
      this.fallbackTargets.forEach((el) => {
        el.remove();
      });
    }
  }

  /**
   * Update value on data attribute (only necessary when dependent controllers exist)
   */
  update(event) {
    const { target } = event;
    if (this.fallback) {
      const baseName = target.id.slice(0, -3);
      const date = DateFieldController.constructDate(baseName);
      target.parentNode.setAttribute('data-value', date);
    } else {
      target.parentNode.setAttribute('data-value', event.target.value);
    }
  }

  /**
   * Get values from Rails select box components and return as single string
   * Note - this function expects a select box to exist for year, month and date
   * @return String date, eg. '2018-11-24'
   */
  static constructDate(baseName) {
    const parts = ['1i', '2i', '3i'];
    const values = parts.map(part => DateFieldController.selectValue(baseName, part));
    return values.join('-');
  }

  /**
   * Get value from an individual element by id
   * @param baseName the base of the id, eg. 'compliance_chart_start_date'
   * @param part the part, eg '1i' for year
   * @return String the value of the element
   */
  static selectValue(baseName, part) {
    return document.getElementById(`${baseName}_${part}`).value;
  }
}

DateFieldController.targets = [
  'native',
  'fallback',
];

export default DateFieldController;
