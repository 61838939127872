import ApplicationController from './support/application_controller';

/**
 * Permit Print Maps controller
 */
class PermitPrintMapSelectController extends ApplicationController {
  /**
   * Show options input for select fields
   */
  select() {
    this.permanentQuestionsTarget.innerHTML = '';
    if (this.printMapTarget.value !== '') {
      this.loadPermanentQuestions(this.printMapTarget.value);
      this.loadPreviewLink(this.printMapTarget.value);
    }
  }

  loadPermanentQuestions(printMapId) {
    fetch(`/city/permit_print_maps/${printMapId}/permanent_questions`,
      {
        credentials: 'same-origin',
      })
      .then(response => response.text()).then((html) => {
        this.permanentQuestionsTarget.innerHTML = html;
      });
  }

  loadPreviewLink(printMapId) {
    fetch(`/city/permit_print_maps/${printMapId}/preview_link`,
      {
        credentials: 'same-origin',
      })
      .then(response => response.text()).then((html) => {
        this.previewLinkTarget.innerHTML = html;
      });
  }
}

PermitPrintMapSelectController.targets = ['permanentQuestions', 'printMap', 'previewLink'];

export default PermitPrintMapSelectController;
