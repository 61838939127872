import Chart from 'chart.js';
import ApplicationController from '../support/application_controller';

/**
 * Chart compliance history
 */
class DoughnutChartController extends ApplicationController {
  connect() {
    this.chart = new Chart(this.bodyTarget,
      {
        type: 'doughnut',
        options: {
          legend: { display: false },
        },
      });
    this.render();
  }

  render() {
    this.fetchData().then((json) => {
      this.json = json;
      this.chart.data = this.chartData;
      this.spinnerTarget.classList.add('d-none');
      this.bodyTarget.classList.add('show');
      this.chart.update();
    });
  }

  fetchData() {
    return fetch(this.data.get('url'), { credentials: 'same-origin' }).then(response => response.json());
  }

  /**
   *
   * @return Array of localised dates
   */
  get labels() {
    return Object.keys(this.json);
  }

  get chartData() {
    return {
      labels: this.labels,
      datasets: [{
        data: Object.values(this.json),
        backgroundColor: this.backgroundColor,
      }],
    };
  }

  get backgroundColor() {
    return Object.values(this.json).map(() => {
      const x = Math.floor(Math.random() * 256);
      const y = Math.floor(Math.random() * 256);
      const z = Math.floor(Math.random() * 256);
      return `rgb(${x},${y},${z})`;
    });
  }
}

DoughnutChartController.targets = ['spinner', 'body'];

export default DoughnutChartController;
