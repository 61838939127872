import { Controller } from 'stimulus';

/**
 * Manages EstablishmentsGreaseTraps notification settings
 */
class EstablishmentsGreaseTrapsController extends Controller {
  connect() {
    this.fetchSettings();
  }

  onCheckboxChange(event) {
    // this means "const type = event.target.dataset.type"
    const { type } = event.target.dataset;
    // convert true and false to 1 and 0
    const value = Number(event.target.checked);
    this.updateSettings(type, value);
  }

  updateCheckboxes(data) {
    this.upcomingCheckboxTarget.checked = data.upcoming_notifications;
    this.overdueCheckboxTarget.checked = data.overdue_notifications;
  }

  greaseTrapId() {
    return this.greaseTrapTarget.dataset.greaseTrapId;
  }

  establishmentId() {
    return this.greaseTrapTarget.dataset.establishmentId;
  }

  fetchSettings() {
    return fetch(this.fetchUrl())
      .then(response => response.json())
      .then((data) => {
        this.updateCheckboxes(data);
      });
  }

  fetchUrl() {
    return '/city/establishments_grease_traps/settings?'
           + `establishment_id=${this.establishmentId()}&`
           + `grease_trap_id=${this.greaseTrapId()}`;
  }

  updateSettings(type, value) {
    const data = this.postData(type, value);
    const request = new XMLHttpRequest();
    request.open('POST', '/city/establishments_grease_traps/update_settings');
    request.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    request.send(data);
  }

  postData(type, value) {
    const data = new FormData();
    data.append('establishment_id', this.establishmentId());
    data.append('grease_trap_id', this.greaseTrapId());
    data.append(type, value);
    return data;
  }
}

EstablishmentsGreaseTrapsController.targets = ['greaseTrap', 'upcomingCheckbox', 'overdueCheckbox'];

export default EstablishmentsGreaseTrapsController;
