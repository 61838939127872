/* global ActiveStorage */
class EmbedUploader {
  /**
   *
   * @param attachment Trix attachment
   * @param url Rails direct upload url
   * @param cb Callback
   */
  constructor(attachment, url, cb, fieldName) {
    this.attachment = attachment;
    this.url = url;
    this.cb = cb;
    this.fieldName = fieldName;
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', this.directUploadProgress.bind(this));
  }

  directUploadProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    return this.attachment.setUploadProgress(progress);
  }

  upload() {
    this.upload = new ActiveStorage.DirectUpload(this.attachment.file, this.url, this);

    this.upload.create((error, blob) => {
      if (error) { return this.attachment.remove(); }

      this.cb.call(this, blob, this.fieldName);

      const imageUrl = this.imageUrl(blob);

      return this.attachment.setAttributes({ url: imageUrl, href: imageUrl });
    });
  }

  imageUrl(blob) {
    return `${this.url.replace('direct_uploads', 'blobs')}/${blob.signed_id}/${blob.filename}`;
  }
}

export default EmbedUploader;
