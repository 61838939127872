import ApplicationController from '../support/application_controller';

/**
 * Control adding and removing options
 */
class RemoveController extends ApplicationController {
  /**
   * Remove option
   */
  remove() {
    const closest = this.element.closest('.js-options');
    if (closest.children.length === 1) {
      this.optionTargets.forEach((element) => {
        const el = element;
        el.value = '';
      });
    } else {
      this.element.remove();
    }
  }
}

RemoveController.targets = ['option'];

export default RemoveController;
