import { Controller } from 'stimulus';

/**
 * Search contacts by name
 */
class ContactSearchController extends Controller {
  initialize() {
    this.selectedValues = this.element.value.split(' ');
    this.$element = $(this.element);

    this.setContactType();
    this.setResourceAccessor();

    this.initializeSelectize();
  }

  setContactType() {
    if (this.element.dataset.contactType === 'provider_contact') {
      this.contactType = 'provider_contacts';
    } else {
      this.contactType = 'contacts';
    }
  }

  setResourceAccessor() {
    if (this.contactType === 'provider_contacts') {
      this.resourceAccessor = 'provider_contact';
    } else {
      this.resourceAccessor = 'contact';
    }
  }

  doesInputHasValue() { return this.element.value !== ''; }

  disconnect() {
    this.$element[0].selectize.destroy();
  }

  async initializeSelectize() {
    let options;

    if (this.doesInputHasValue()) {
      options = await this.fetchOptions();
    } else {
      options = [];
    }

    this.$element.selectize({
      valueField: 'id',
      labelField: 'full_name',
      searchField: ['full_name', 'main_email'],
      items: this.selectedValues,
      options,
      maxItems: 20,
      create: false,
      render: {
        item: this.constructor.buildItemNode,
        option: this.constructor.buildOptionNode,
      },
      load: this.fetchData.bind(this),
    });
  }

  static buildItemNode(item, escape) {
    let email = 'No main E-mail';
    const name = escape(item.full_name);

    if (item.main_email) { email = escape(item.main_email); }

    return '<div class="d-flex flex-column">'
           + `<div>${name}</div><small>${email}</small></div>`;
  }

  static buildOptionNode(item, escape) {
    const name = escape(item.full_name);
    let email = 'No main E-mail';

    if (item.main_email) { email = escape(item.main_email); }

    return '<div class="list-group-item p-2 d-flex flex-column">'
           + `<div>${name}</div><small>${email}</small></div>`;
  }

  static async promiseSerial(funcs) {
    return funcs.reduce((p, f) => p.then(r => f().then(Array.prototype.concat.bind(r))),
      Promise.resolve([]));
  }

  static fetchContact(url, accessor) {
    return fetch(url).then(response => response.json()).then(json => json[accessor]);
  }

  async fetchOptions() {
    const urls = this.selectedValues.map(id => `/city/${this.contactType}/${id}.json`);
    const funcs = urls.map(
      url => () => ContactSearchController.fetchContact(url, this.resourceAccessor),
    );

    return ContactSearchController.promiseSerial(funcs).then(result => result);
  }

  fetchData(query, callback) {
    this.query = query;
    if (!query.length || query.length < 2) return callback();
    return fetch(`/city/${this.contactType}.json?${encodeURIComponent('q[full_name_cont]')}=${encodeURIComponent(query)}`, { credentials: 'same-origin' })
      .then(response => response.json())
      .then((data) => {
        callback(data[this.contactType]);
      });
  }
}

export default ContactSearchController;
