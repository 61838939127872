import { Controller } from 'stimulus';

/**
 * Toggle all checkboxes
 */
class CheckAllController extends Controller {
  toggle() {
    this.checkTargets.forEach((element) => {
      const box = element;
      box.checked = this.triggerTarget.checked;
    });
  }

  indeterminate() {
    this.triggerTarget.indeterminate = true;
  }
}

CheckAllController.targets = [
  'trigger',
  'check',
];

export default CheckAllController;
