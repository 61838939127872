export default class BootstrapModalAdapter {
  constructor() {
    this.modalClass = '.js-new-form-modal';
    this.modalTitleClass = '.js-modal-title';
    this.modalBodyClass = '.js-modal-body';
  }

  open() {
    $(this.modalClass).modal('show');
  }

  close() {
    $(this.modalClass).modal('hide');
  }

  setTitle(title) {
    $(this.modalTitleClass).html(title);
  }

  setBody(body) {
    $(this.modalBodyClass).html(body);
  }
}
