import { Controller } from 'stimulus';
import BootstrapModalAdapter from '../adapters/bootstrap_modal_adapter';

class CsvExportFormController extends Controller {
  connect() {
    this.modal = new BootstrapModalAdapter();
  }

  openModal(event) {
    event.preventDefault();

    this.modal.open();

    this.modal.setTitle(this.modalTriggerTarget.dataset.modalTitle);

    fetch(this.modalTriggerTarget.dataset.exportFormPath)
      .then(response => response.text())
      .then((text) => { this.modal.setBody(text); });
  }
}

CsvExportFormController.targets = ['modalTrigger'];

export default CsvExportFormController;
