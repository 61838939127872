import { Controller } from 'stimulus';

/**
 * Application Controller
 */
class ApplicationController extends Controller {
  insertContent(position) {
    this.fetchData()
      .then((html) => {
        this.element.insertAdjacentHTML(position, html);
      });
  }

  fetchData() {
    return fetch(this.data.get('url'), { credentials: 'same-origin' })
      .then(response => response.text());
  }

  /**
   *
   * Find a stimulus controller by name and return it
   * @return Stimulus controller
   */
  getControllerByIdentifier(name) {
    return this.application.controllers.find(controller => controller.context.identifier === name);
  }
}

export default ApplicationController;
